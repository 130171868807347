export default defineNuxtRouteMiddleware(async () => {
  const { $localePath: localePath } = useNuxtApp();
  const authStore = useAuthStore();

  if (authStore.accessToken !== null && !authStore.isLoggedIn) {
    await authStore.getSession();
  }

  if (authStore.isLoggedIn && authStore.isPremiumPartner) {
    return;
  }

  return navigateTo(localePath({ name: "index" }));
});
